// SignUp.js
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import {
	GoogleAuthProvider,
	signInWithPopup,
	createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import getAuthErrorMessage from "../constants/firebaseErrorMessages";

import { useAuth } from "../context/AuthContext";

const SignUp = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");

	const navigate = useNavigate();
	const location = useLocation();
	const redirectTo = location.state?.from?.pathname || "/";

	const { currentUser } = useAuth();

	const handleEmailSignUp = async (e: React.FormEvent) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setError("Passwords do not match");
			return;
		}
		try {
			await createUserWithEmailAndPassword(auth, email, password);
			navigate(redirectTo);
		} catch (err: any) {
			setError(getAuthErrorMessage(err.code));
		}
	};

	const handleGoogleSignIn = async () => {
		const provider = new GoogleAuthProvider();
		try {
			await signInWithPopup(auth, provider);
			navigate(redirectTo);
		} catch (err: any) {
			setError(getAuthErrorMessage(err.code));
		}
	};

	return (
		<>
			{currentUser && <Navigate to="/" />}

			<div className="flex min-h-screen items-center justify-center p-4">
				<div className="w-full max-w-md rounded-lg bg-white p-6 shadow-md dark:bg-gray-800">
					<h2 className="mb-6 text-center text-2xl font-bold text-gray-800 dark:text-gray-200">
						Create an Account
					</h2>

					<button
						onClick={handleGoogleSignIn}
						className="mb-4 flex w-full items-center justify-center rounded-full border border-gray-300 bg-white py-2 text-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
					>
						<FcGoogle size={24} className="mr-2" /> Sign In with
						Google
					</button>
					<div className="mb-4 flex items-center">
						<div className="w-full border-t border-gray-300 dark:border-gray-600"></div>
						<span className="mx-4 text-gray-500 dark:text-gray-400">
							OR
						</span>
						<div className="w-full border-t border-gray-300 dark:border-gray-600"></div>
					</div>
					{error && <div className="mb-4 text-red-500">{error}</div>}
					<form onSubmit={handleEmailSignUp} className="w-full">
						<div className="mb-4">
							<label className="mb-2 block text-gray-800 dark:text-gray-200">
								Email
							</label>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="w-full rounded-lg border border-gray-300 bg-gray-100 p-2 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:bg-gray-700"
								required
							/>
						</div>
						<div className="mb-4">
							<label className="mb-2 block text-gray-800 dark:text-gray-200">
								Password
							</label>
							<input
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="w-full rounded-lg border border-gray-300 bg-gray-100 p-2 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:bg-gray-700"
								required
							/>
						</div>
						<div className="mb-4">
							<label className="mb-2 block text-gray-800 dark:text-gray-200">
								Confirm Password
							</label>
							<input
								type="password"
								value={confirmPassword}
								onChange={(e) =>
									setConfirmPassword(e.target.value)
								}
								className="w-full rounded-lg border border-gray-300 bg-gray-100 p-2 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:bg-gray-700"
								required
							/>
						</div>
						<button
							type="submit"
							className="mb-4 w-full rounded-full bg-blue-500 py-2 font-bold text-white hover:bg-blue-600"
						>
							Sign Up
						</button>
					</form>
					<div className="flex flex-col items-center md:flex-row md:justify-between">
						<Link
							to="/signin"
							state={{ from: location.state?.from || "/" }}
							className="mt-2 text-blue-500 hover:underline md:mt-0"
						>
							Already have an account? Sign In
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignUp;
