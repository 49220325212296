import React, { useEffect, useState } from "react";
import BlogPostCard from "./BlogPostCard";

import IPost from "../types/IPost";

import useFirebase from "../hooks/useFirebase";

const RecentThreePostList = () => {
	const { getRecentPosts } = useFirebase();
	const [posts, setPosts] = useState<IPost[]>([]);

	useEffect(() => {
		// console.log("Posts in RecentThreePostList running UseEffect");
		const fetchThreePosts = async () => {
			const recentPosts = (await getRecentPosts(0, 3)) as IPost[];
			setPosts(recentPosts);
		};

		fetchThreePosts();
	}, []);

	return (
		<div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
			{posts.map((post) => (
				<BlogPostCard key={post.id} post={post} />
			))}
		</div>
	);
};

export default RecentThreePostList;
