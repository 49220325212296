// MarkdownEditor.tsx
import { useState, useEffect } from "react";
import MDEditor from "@uiw/react-markdown-editor";
import useTheme from "../hooks/useTheme";

interface IMarkdownEditorProps {
	markdown: string;
	setMarkdown: (value: string) => void;
}

const MarkdownEditor = ({ markdown, setMarkdown }: IMarkdownEditorProps) => {
	const { isDarkTheme, theme } = useTheme();

	useEffect(() => {
		console.log("Theme change in MarkdownEditor:", isDarkTheme);
		console.log("Theme change in MarkdownEditor:", theme);
	}, [isDarkTheme, theme]);

	return (
		<div className={`flex flex-col md:flex-row`}>
			<div
				id="markdown-editor-parent"
				className="flex-1 overflow-hidden p-4"
				data-color-mode={isDarkTheme ? "dark" : "light"} //TODO: Dark mode not working without full page refresh // Maybe I should use ThemeContext and in there useTheme hook
			>
				<MDEditor
					value={markdown}
					enableScroll={true}
					height="80vh"
					onChange={setMarkdown}
					className={`${isDarkTheme ? "dark" : ""}`}
				/>
			</div>
		</div>
	);
};

export default MarkdownEditor;
