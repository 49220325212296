// src/pages/Write.tsx
import React, { useEffect, useState } from "react";
import MarkdownEditor from "../components/MarkdownEditor";
import useFirebase from "../hooks/useFirebase";
import { useParams } from "react-router-dom";

import { Navigate, useNavigate, useLocation } from "react-router-dom";

import IPost from "../types/IPost";

import { useAuth } from "../context/AuthContext";

const Write: React.FC = () => {
	const { currentUser } = useAuth();
	const { id } = useParams<{ id: string }>();
	
	const navigate = useNavigate()

	const [title, setTitle] = useState("");
	const [excerpt, setExcerpt] = useState("");
	const [tags, setTags] = useState<string | string[]>("");
	const [image, setImage] = useState<File | null>(null);
	const [isPremiumOnlyPost, setIsPremiumOnlyPost] = useState<boolean>(false)

	const [markdown, setMarkdown] = useState("");
	const [displayName, setDisplayName] = useState("");
	const location = useLocation();
	const { createPost, loading, error, getPostById, updatePost, checkUserIsAdmin } = useFirebase();

	useEffect(() => {
		const fetchBlogPost = async () => {
			try {
				const fetchedBlog = (await getPostById(id!)) as IPost;
				
				setTitle(fetchedBlog.title)
				setExcerpt(fetchedBlog.excerpt)
				setTags(fetchedBlog.tags)

				setIsPremiumOnlyPost(fetchedBlog.isPremiumOnlyPost)
				
				setMarkdown(fetchedBlog.body)

			} catch (err: any) {
				console.log("Write.tsz>> fetch ")
				alert("Failed to load post! Try edting later.")
			}
		};
		

		if(id) {
			fetchBlogPost();
		}
	}, [id])

	useEffect(()=> {
		const check = async () => {
			const c = await checkUserIsAdmin(currentUser.uid)

			if (!c){
				navigate('/')
			}
		}

		check()
	}, [])

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!title || !markdown) {
			alert("Title and content are required");
			return;
		}

		// UPDATE POST
		if(id){
			try {
				let post = {
					title,
					excerpt,
					tags: typeof(tags) == "string" ? tags.split(",").map((tag) => tag.trim()) : tags,
					mainImage: image,
					body: markdown,
					isPremiumOnlyPost,
				};
	
				await updatePost(id, post);
				alert("Post updated successfully!");
				navigate(`/post/${id}`)
			} catch (err) {
				console.error(err);
			}


			return 0
		}

		// CREATE POST
		try {
			let post = {
				title,
				excerpt,
				tags: typeof(tags) == "string" ? tags.split(",").map((tag) => tag.trim()) : tags,
				mainImage: image,
				body: markdown,
				displayName: displayName,
				isPremiumOnlyPost
			};

			const newPostId = await createPost(post);
			alert("Post created successfully!");
			navigate(`/post/${newPostId}`)
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="container mx-auto max-w-4xl p-4">
			{!currentUser && (
				<Navigate to="/signin" state={{ from: location }} />
			)}

			<h1 className="mb-6 text-center text-3xl font-bold">
				{
					id ? "Edit Your Post" : "Write a New Post"
				}
			</h1>
			<form onSubmit={handleSubmit} className="space-y-6">
				{currentUser && !currentUser.displayName && (
					<div>
						<label
							htmlFor="displayName"
							className="mb-2 block text-lg font-medium"
						>
							Display Name
						</label>
						<input
							type="text"
							id="displayName"
							value={displayName}
							onChange={(e) => setDisplayName(e.target.value)}
							className="w-full rounded-md border border-gray-300 p-3 focus:border-blue-500 focus:ring-blue-500"
							placeholder="Enter display name"
							required
						/>
					</div>
				)}

				<div>
					<label
						htmlFor="title"
						className="mb-2 block text-lg font-medium"
					>
						Title
					</label>
					<input
						type="text"
						id="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						className="w-full rounded-md border border-gray-300 p-3 focus:border-blue-500 focus:ring-blue-500"
						placeholder="Enter post title"
						required
					/>
				</div>
				<div>
					<label
						htmlFor="tags"
						className="mb-2 block text-lg font-medium"
					>
						Tags{" "}
						<span className="text-sm text-gray-500">
							(Comma separated)
						</span>
					</label>
					<input
						type="text"
						id="tags"
						value={tags}
						onChange={(e) => setTags(e.target.value)}
						className="w-full rounded-md border border-gray-300 p-3 focus:border-blue-500 focus:ring-blue-500"
						placeholder="Enter tags separated by commas"
					/>
				</div>
				<div>
					<label
						htmlFor="excerpt"
						className="mb-2 block text-lg font-medium"
					>
						Excerpt
					</label>
					<textarea
						id="excerpt"
						value={excerpt}
						onChange={(e) => setExcerpt(e.target.value)}
						className="w-full rounded-md border border-gray-300 p-3 focus:border-blue-500 focus:ring-blue-500"
						placeholder="Enter post excerpt"
						required
					/>
				</div>
				<div>
					<label
						htmlFor="image"
						className="mb-2 block text-lg font-medium"
					>
						Main Image
					</label>
					<input
						type="file"
						accept="image/*"
						id="image"
						onChange={(e) =>
							setImage(e.target.files ? e.target.files[0] : null)
						}
						className="w-full rounded-md border border-gray-300 p-3"
						required={id ? false : true}
					/>
				</div>
				

				<div>
					<label
						htmlFor="body"
						className="mb-2 block text-lg font-medium"
					>
						Body
					</label>
					<MarkdownEditor
						markdown={markdown}
						setMarkdown={setMarkdown}
					/>
				</div>
				<div className="mx-10 flex-col sm:flex-row mt-6 flex justify-between">
					<label className=" items-center  mb-6 sm:mb-0 flex flex-row align-middle">
		<input
			type="checkbox"
			checked={isPremiumOnlyPost}
			onChange={(e) => {
				e.preventDefault()
				setIsPremiumOnlyPost(prev => !prev)
			}}
			className="mr-2"
		/>
		<span className="text-lg font-medium">Premium Only Post</span>
	</label>


					<button
						className="rounded-full bg-blue-600 px-8 py-3 font-semibold text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
						type="submit"
						disabled={loading}
					>
						{loading ? "Publishing..." : "Publish"}
					</button>
				</div>
			</form>

			{error && <p className="mt-4 text-red-500">{error}</p>}
		</div>
	);
};

export default Write;
