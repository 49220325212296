// src/pages/About.tsx
import React from "react";

const About: React.FC = () => {
	return (
		<div className="container mx-auto px-4 py-8">
			<h1 className="mb-4 text-4xl font-bold">About Cool Press</h1>
			<p className="mb-4 text-lg">
				Welcome to CoolPress! We are dedicated to providing the best
				content and resources for our audience.
			</p>
			<section className="mb-8">
				<h2 className="mb-2 text-2xl font-semibold">Our Mission</h2>
				<p className="text-lg">
					At CoolPress, we value different perspectives, authentic
					ideas, and the unique imaginations of every individual. Our
					mission is to enlighten, entertain, and inspire thoughtful
					thinking in an increasingly complex world. We strive to
					spread the power of optimism through content that is both
					humanistic and realistic.
				</p>
			</section>
			<section className="mb-8">
				<h2 className="mb-2 text-2xl font-semibold">
					What We Stand For
				</h2>
				<p className="text-lg">At CoolPress, we believe in:</p>
				<ul className="list-inside list-disc text-lg">
					<li>
						Enlightening our readers with insightful and diverse
						perspectives.
					</li>
					<li>
						Bringing humor and light-hearted content to brighten
						your day.
					</li>
					<li>Influencing thoughtful and reflective thinking.</li>
					<li>
						Promoting optimism with a realistic and humanistic
						approach.
					</li>
				</ul>
			</section>
			<section>
				<h2 className="mb-2 text-2xl font-semibold">Join Us</h2>
				<p className="text-lg">
					Whether you're here to read, laugh, or reflect, we are glad
					to have you with us. Explore our content, share your
					thoughts, and be a part of the CoolPress community.
				</p>
			</section>
		</div>
	);
};

export default About;
