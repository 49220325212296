import React from "react";
import { Link } from "react-router-dom";
import IPost from "../types/IPost";

const BlogPostCard = ({ post }: { post: IPost }) => {
	return (
		<div className="max-w-sm overflow-hidden rounded-lg bg-white shadow-md transition-all duration-200 dark:bg-gray-800">
			<img
				className="h-48 w-full object-cover"
				src={post.mainImage}
				alt={post.title}
			/>
			<div className="p-6">
				<div className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
					{post.title}
				</div>
				<p className="text-gray-700 dark:text-gray-300">
					{post.excerpt}
				</p>
			</div>
			<div className="px-6 py-4">
				<Link
					to={`/post/${post.id}`}
					className="text-blue-500 hover:underline dark:text-blue-400"
				>
					Read more
				</Link>
			</div>
		</div>
	);
};

export default BlogPostCard;
