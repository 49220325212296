import React, { useEffect, useState } from "react";

import BlogPostItem from "../components/BlogPostItem";
import useFirebase from "../hooks/useFirebase";

import { useParams } from "react-router-dom";

import IPost from "../types/IPost";

const RecentPostsPage: React.FC = () => {

	const { uid } = useParams<{ uid: string }>();
	const [recentPosts, setRecentPosts] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	const { getRecentPosts, getPostsByUserId } = useFirebase();

	useEffect(() => {
		const fetchRecentPosts = async () => {
			console.log("fetching RECENT posts")
			try {
				const posts = await getRecentPosts(0, 10);
				setRecentPosts(posts);
			} catch (err: any) {
				setError(err.message);
			}
		};

		const fetchUserPosts = async () => {
			console.log("fetching USER posts")

			try {
				const userPosts = await getPostsByUserId(uid!)
				setRecentPosts(userPosts)
			} catch (err: any) {
				setError(err.message);
			}
		}

		if(uid) {
			fetchUserPosts()
		} else {
			fetchRecentPosts();
		}



	}, []);

	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<div className="pt-8">
			{recentPosts.map((post) => (
				<BlogPostItem key={post.id} post={post} />
			))}
		</div>
	);
};

export default RecentPostsPage;
