// src/components/NavBar.tsx
import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import { IoSearch } from "react-icons/io5";
import { FaPen } from "react-icons/fa";

import { useAuth } from "../context/AuthContext";
import useFirebase from "../hooks/useFirebase";

import ThemeToggle from "./ThemeToggle";

import Logo from "./Logo";

import {
	Navbar,
	NavbarBrand,
	NavbarMenuToggle,
	NavbarMenuItem,
	NavbarMenu,
	NavbarContent,
	NavbarItem,
	DropdownItem,
	Input,
	DropdownTrigger,
	Dropdown,
	DropdownMenu,
	Avatar,
} from "@nextui-org/react";

// import {SearchIcon} from "./SearchIcon.jsx";

const NavBar: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false)

	const { currentUser, logout } = useAuth();

	const {checkUserIsAdmin} = useFirebase()

	const navigate = useNavigate();

	useEffect(()=> {
		const check = async () => {
			const c = await checkUserIsAdmin(currentUser.uid)

			setIsAdmin(c)
		}

		if (currentUser){
			check()
		}
	}, [])

	const menuItems = [
		["Posts", "/posts"],
		["About", "/about"],
		// ["PMS", "/"], //TODO: Add the link back later
		// ["Settings", "/settings"],
		["Contact Us", "/contact"],
	];

	return (
		<Navbar
			isBordered={true}
			isMenuOpen={isMenuOpen}
			onMenuOpenChange={setIsMenuOpen}
			shouldHideOnScroll
			maxWidth="xl"
		>
			<NavbarContent className="sm:hidden" justify="start">
				<NavbarMenuToggle
					aria-label={isMenuOpen ? "Close menu" : "Open menu"}
				/>
			</NavbarContent>

			<NavbarContent className="pr-3 sm:hidden" justify="start">
				<NavbarBrand>
					<NavLink to="/">
						<div className="flex items-center">
							<Logo />
						</div>
					</NavLink>
				</NavbarBrand>
			</NavbarContent>

			<NavbarContent className="hidden gap-4 sm:flex" justify="start">
				<NavbarBrand>
					<NavLink to="/">
						<div className="flex items-center">
							<Logo />
							<p className="text-2xl font-bold text-blue-400">
								Cool Press
							</p>
						</div>
					</NavLink>
				</NavbarBrand>

				<NavbarItem>
					<NavLink className="nav-link" to="/posts">
						Posts
					</NavLink>
				</NavbarItem>

				<NavbarItem>
					<NavLink className="nav-link" to="/about">
						About
					</NavLink>
				</NavbarItem>

				<NavbarItem>
					<NavLink className="nav-link" to="/contact">
						Contact
					</NavLink>
				</NavbarItem>

				{/* <NavbarItem>
					<NavLink
						to="/"
						onClick={() => {
							alert("PMS is Coming Soon!");
						}}
						color="foreground"
					>
						<span className="rainbow-text font-bold">PMS</span>
					</NavLink>
				</NavbarItem> */}
			</NavbarContent>

			<NavbarContent as="div" className="items-center" justify="end">
				{isAdmin && <NavbarItem>
					<NavLink className="nav-link" to="/write">
						<div className="flex items-center space-x-2 rounded-full border-2 border-blue-500 px-4 py-2 transition-transform duration-300 hover:scale-105 hover:border-blue-700">
							<span className="write-text hidden font-semibold text-blue-500 hover:text-blue-700 md:inline">
								Write
							</span>

							<FaPen className="pen-icon text-blue-500" />
						</div>
					</NavLink>
				</NavbarItem>}
				<Input
					classNames={{
						base: "max-w-full sm:max-w-[10rem] h-10 hidden sm:flex", //TODO: Add Search option for small screens, like this one small screen and big screen -> https://nextui.org/docs/customization/theme
						mainWrapper: "h-full",
						input: "text-small",
						inputWrapper:
							"h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20",
					}}
					placeholder="Type to search..."
					size="sm"
					startContent={<IoSearch />}
					type="search"
				/>
				{currentUser ? (
					<Dropdown placement="bottom-end">
						<DropdownTrigger className="hidden sm:inline">
							<Avatar
								isBordered
								as="button"
								className="transition-transform"
								color="secondary"
								size="sm"
								// src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
							/>
						</DropdownTrigger>
						<DropdownMenu
							aria-label="Profile Actions"
							variant="flat"
							onSelect={(s) => console.log("Selected", s)}
						>
							<DropdownItem key="profile" className="h-14 gap-2">
								<p className="font-semibold">Signed in as</p>
								<p className="font-semibold">
									{currentUser.email}
								</p>
							</DropdownItem>
							{/* <DropdownItem
								key="settings"
								onClick={() => navigate("/settings")}
							>
								Settings
							</DropdownItem> */}
							

							<DropdownItem
								key="help_and_feedback"
								onClick={() => navigate("/contact")}
							>
								Contact Us
							</DropdownItem>
							<DropdownItem
								key="logout"
								color="danger"
								onClick={logout}
							>
								Log Out
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				) : (
					<>
						<NavbarItem className="hidden lg:flex">
							<NavLink to="/signin">Login</NavLink>
						</NavbarItem>
						<NavbarItem>
							<NavLink to="/signup" className="">
								<button className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-600">
									Sign Up
								</button>
							</NavLink>
						</NavbarItem>
					</>
				)}
				<ThemeToggle />
			</NavbarContent>

			<NavbarMenu>
				{
					currentUser && (
						<NavbarItem>
					Signed-in as {currentUser.email}
				</NavbarItem>
					)
				}
				{menuItems.map((item, index) => (
					<NavbarMenuItem key={`${item}-${index}`}>
						<NavLink
							className="w-full"
							color={
								index === 2
									? "warning"
									: index === menuItems.length - 1
										? "danger"
										: "foreground"
							}
							to={item[1]}
							onClick={() => setIsMenuOpen(false)}
						>
							{item[0]}
						</NavLink>
					</NavbarMenuItem>
				))}
				<NavbarMenuItem
					key="logout"
					onClick={() => {
						logout();
						setIsMenuOpen(false);
					}}
				>
					Log Out
				</NavbarMenuItem>
			</NavbarMenu>
		</Navbar>
	);
};

export default NavBar;
