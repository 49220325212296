// src/pages/About.tsx
import React from "react";
import { Link } from "react-router-dom";

const CopyRight: React.FC = () => {
	return (
		<div className="mx-auto max-w-4xl p-6">
			<h1 className="mb-4 text-3xl font-bold">Copyright Notice</h1>
			<p className="mb-4">
				© {new Date().getFullYear()} Cool Press. All rights reserved.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">1. Ownership</h2>
			<p className="mb-4">
				The content, design, and layout of this website, including but
				not limited to text, images, graphics, and software, are owned
				by Cool Press and are protected by copyright and other
				intellectual property laws.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				2. Use of Content
			</h2>
			<p className="mb-4">
				You may not reproduce, distribute, or modify any content from
				this website without the prior written consent of Cool Press.
				For permissions, please{" "}
				<Link className="text-blue-500" to="/contact">
					contact us
				</Link>
				.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">3. Trademarks</h2>
			<p className="mb-4">
				All trademarks, logos, and service marks displayed on this
				website are the property of Cool Press or third parties. Use of
				these marks without prior written consent is prohibited.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				4. Contact Information
			</h2>
			<p className="mb-4">
				If you have any questions regarding this copyright notice or
				would like to request permission for use of any content, please{" "}
				<Link className="text-blue-500" to="/contact">
					contact us
				</Link>
				.
			</p>
			<p className="mt-4">Last Updated: Aug 08, 2024</p>
		</div>
	);
};

export default CopyRight;
